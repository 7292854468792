export const environment = {
  production: false,
  apiUrl: 'https://uaedemoadminbackend.hrmlix.com',
  BASEHREF: 'api/admin-backend-service',
  APPNAME: 'The Seamless HR Solution',
  DATATABLELENGTH: 20,
  DATATABLELENGTHCHANGEMENU: [1, 10, 20, 50, 75, 100],
  USERAUTH: '',
  RAZORPAYKEY: 'rzp_test_XT2LimGxwnmnYW',
  RAZORPAYCOMPANYNAME: 'Hrmlix Software',
  RAZORPAYCOMPANYLOGO: 'https://cdn3.iconfinder.com/data/icons/inficons-currency-set/512/rupee-512.png',
  RAZORPAYCURRENCY: 'INR'
};
